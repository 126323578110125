import { useEffect } from "react";
import "./styles.css";
import Map from "react-map-gl";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { Protocol } from "pmtiles";
import layers from 'protomaps-themes-base';

export default function App() {
  useEffect(() => {
    let protocol = new Protocol();
    maplibregl.addProtocol("pmtiles", protocol.tile);
    return () => {
      maplibregl.removeProtocol("pmtiles");
    };
  }, []);

  return (
    <div className="App">
      <Map
        style={{ width: '100wv', height: '99vh', margin: 0, padding: 0 }}
        mapStyle={{
            version:8,
            center: [25.279652, 54.687157, ],
            zoom: 16,
            glyphs:'https://protomaps.github.io/basemaps-assets/fonts/{fontstack}/{range}.pbf',
            sources: {
                "protomaps": {
                    type: "vector",
                    url: "pmtiles://https://pmtiles.vycius.lt/proton.pmtiles",
                    //       ^-- Remember to prefix the URL with pmtiles://
                    attribution: '<a href="https://protomaps.com">Protomaps</a> © <a href="https://openstreetmap.org">OpenStreetMap</a>'
                }
            },
            layers: layers("protomaps","light")
        }}
        mapLib={maplibregl}
      />
    </div>
  );
}
